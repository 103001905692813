
.about-panel {
    position: fixed;
    color: #151515;
    left: 0;
    font-weight: 300;
    height: 100vh;
    overflow: hidden;
    min-width: 277px;

    .logo-link {
        height: 30px;
        margin: 24px;
    }
    
    .resume-link {
        float: right;
        color: #151515;
        text-decoration: none;
        font-size: 14px;
        font-weight: 700;
        line-height: 30px;
        margin: 24px;
    }
    .name-text {
        font-size: 24px;
        margin-bottom: 24px;
        font-weight: 500;
    }

    .about-text {
        font-size: 20px;
        line-height: 26px;
        position: absolute;
        top: 40%;
        transform: translateY(-50%);
        margin: 24px;
    }

    .footer {
        bottom: 24px;
        position: absolute;
        color: #151515;
        margin: 24px;
        width: calc(100% - 48px);
        .email {
            padding-bottom: 12px;
        }
        .social-icons {
            width: 100%;
            border-top: 2px solid #151515;
            padding-top: 12px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            svg {
                fill: #151515;
            }

        }
    }
}

@media only screen and (max-width: 750px) {
    .about-panel {
      height: 100vh;
    }
}