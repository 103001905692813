.design-panel {
    position: absolute;
    right: 0;
    min-height: 100vh;

    .images-container {
        .image-container {
            color: #151515;
            text-decoration: none;
            margin-top: 8px;
            height: 325px;
            padding: 1.75rem;
            padding-bottom: 0;
            overflow: hidden;
            background-size: cover;
            background-repeat: no-repeat;
            a {
                color: #151515;
                text-decoration: none;
            }
        }
        img {
            border-radius: 4px 4px 0px 0px;
            max-width: 75%;
            margin: auto;
            display: block;
            &:hover {
                transform: translateY(-5px) scale(1);
                transition: transform 0.75s ease-in-out;
            }

        }
        .card-title {
            font-weight: bold;
            text-transform: uppercase;
            color: #151515;
            font-size: 20px;
            margin-bottom: 24px;
            svg {
                font-size: 16px;
                margin-left: 8px;
            }
        }
        .card-info {
            font-size: 16px;
            float: right;
        }
    }
    @media only screen and (max-width: 750px) {

        .images-container {
            .card-info {
                float: left;
                margin-bottom: 16px;
            }
            .image-container {
                margin-top: 0;
            }
        }
    }
        
    
}

@media only screen and (max-width: 750px) {
        
    .design-panel {
        position: relative;
        top: 100vh;
    }
}
