.entry-manager {
    position: absolute;
    right: 0;
    min-height: 100vh;
    color: #151515;
    .col-6 {
        width: 75%;
    }
    .overview {
        font-size: 18px;
        line-height: 25px;
        a {
            font-weight: 600;
            color: #e66760;
            text-decoration: none;
        }
    }
    .header {
        height: 100vh;
        position: absolute;
    }
    .header-image {
        width: 100%;
    }
    .col-5 {
        border-top: 2px solid #151515;
        margin-top: 24px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .content {
            width: 50%;
            font-size: 18px;
            line-height: 25px;
            padding-right: 24px;
        }
        h3 {
            width: 50%;
            font-size: 30px;
            margin-top: 18px;
        }
        .header-image {
            width: 50%;
        }
    }
    .paragraph-break {
        margin-top: 18px;
    }

    .slide-gallery {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        img {
            width: calc(50% - 16px);
            margin: 8px;
        }
    }
    .legacy-gallery {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        img {
            width: calc(33% - 24px);
            margin: 8px;
        }
    }
    .gif-image {
        margin-bottom: 150px;
    }
    .image-full {
        width: calc(99% - 16px);
        margin: 8px;
    }
    .image-gallery-thumbnails-wrapper, .image-gallery-play-button {
        display: none;
    }
    .image-gallery-icon {
        filter: none;
        color: white;
        &:hover {
            color: white;
        }

    }
}


@media only screen and (max-width: 750px) {
    .entry-manager {
        top: 100vh;
        #em-header .content {
            padding: 0 24px;
        }
        .col-5 {
            display: block;
            padding: 0 24px;

            .content {
                width: 100%;
            }
        }
        h4 {
            padding: 0 24px;
        }
        .gif-image {
            margin-bottom: 12px;
        }     
    }
  }


