.myskdule {
    position: absolute;
    right: 0;
    min-height: 100vh;
    color: #151515;
    .col-6 {
        width: 75%;
    }
    .header-image {
        width: 100%;
    }
    .overview {
        font-size: 18px;
        line-height: 25px;
    }
    .col-5 {
        border-top: 2px solid #151515;
        margin-top: 24px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .content {
            width: 50%;
            font-size: 18px;
            line-height: 25px;
            padding-right: 24px;
        }
        h3 {
            width: 50%;
            font-size: 30px;
            margin-top: 18px;
        }
    }
}

@media only screen and (max-width: 750px) {
    .myskdule {
        top: 100vh;
        #ms-myskdule {
            padding: 0 24px;
        }
        .col-5 {
            display: block;
            padding: 0 24px;

            .content {
                width: 100%;
            }
        }
    }
  }